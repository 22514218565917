* {
  margin: 0;
}
@font-face {
  font-family: 'PowerrBold';
  src: local('PowerrBold'),
    url('./config/fonts/Powerr/PowerrBold-Rpz8M.ttf') format('truetype');
}

@font-face {
  font-family: 'PowerrMedium';
  src: local('PowerrMedium'),
    url('./config/fonts/Powerr/PowerrMedium-rgVwO.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins-Regular'),
    url('./config/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}
